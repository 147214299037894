import OrganizationActiveList from "@pages/Organizations/Active";
import OrganizationDetailScreen from "@pages/Organizations/Active/Detail";
import OrganizationInActiveList from "@pages/Organizations/Inactive";

export const AdminOrganisationRoutes = [
  {
    path: "/",
    name: "Organization",
    component: OrganizationActiveList,
  },
  {
    path: "/admin/organisations/active/list",
    name: "Organization",
    component: OrganizationActiveList,
  },
  {
    path: "/admin/organisations/inactive/list",
    name: "Organization",
    component: OrganizationInActiveList,
  },
  {
    path: "/admin/organisations/add",
    name: "Organization",
    component: OrganizationDetailScreen,
  },
  {
    path: "/admin/organisations/active/detail/:id",
    name: "Organization",
    component: OrganizationDetailScreen,
  },
];
