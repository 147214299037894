import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import BulkUpload from "../BulkUpload";
import Search from "../Search";
import "./uploaded-details.scss";
import "./add-filter.scss";
const SearchHeader = (props: any) => {
  return (
    <section className="add-filter-wrapper add-filter-wrapper--border w-100 d-flex align-items-center justify-content-between">
      <div className="add-filter-wrapper__add d-flex">
        {props.add != "false" &&
          (getValue(props, `isAdd`, false) ? (
            <div
              className="add-filter-wrapper__add-icon d-flex cursor-pointer"
              onClick={() => props.handleAddFun()}
              style={{
                backgroundColor: "#fd9933",
                height: "36px",
                width: "36px",
                borderRadius: "50%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                style={{ margin: "auto", textAlign: "center" }}
              >
                <path
                  d="M11 19V13H5V11H11V5H13V11H19V13H13V19Z"
                  fill="#ffffff"
                />
              </svg>
            </div>
          ) : (
            <NavLink to={props.route ? `${props.route}` : "#"} className="px-3">
              <div className="add-filter-wrapper__add-icon d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                  <path
                    d="M11 19V13H5V11H11V5H13V11H19V13H13V19Z"
                    fill="#ffffff"
                  />
                </svg>
              </div>
            </NavLink>
          ))}
        {props.smartFilter && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={props.setFilterFormVisibility}
          >
            <img
              src="/images/filter-icon.svg"
              className="img-fluid cursor-pointer mx-2"
              height={24}
              width={24}
            />
            {props.isHeadingVisibleByDefault && (
              <>
                {props.isFilterHeadingVisible && (
                  <>
                    <h6 className="add-filter-wrapper__filter-status">
                      {props.headingText || "Filter Status"}
                    </h6>
                  </>
                )}
                {props.isFilterSubHeadingVisible && (
                  <h6 className="add-filter-wrapper__add-filter-title">
                    All <span>ADD FILTER</span>
                  </h6>
                )}
              </>
            )}
            {/* {props.isFilterHeadingVisible && (
            <>
              <h6 className="add-filter-wrapper__filter-status">
                {props.headingText || 'Filter Status'}
              </h6>
            </>
          )}
          {props.isFilterSubHeadingVisible && (
            <h6 className="add-filter-wrapper__add-filter-title">
              All <span>ADD FILTER</span>
            </h6>
          )} */}
          </div>
        )}
      </div>
      <div className="add-filter-wrapper__filter d-flex align-items-center">
        <Search
          searchInput={getValue(props, `searchInput`, "")}
          showSearchTextbox={getValue(props, `showSearchTextbox`, "")}
          toggleSearch={props.toggleSearch}
          toggleSearchClose={props.toggleSearchClose}
          handleChangeSearch={props.handleChangeSearch}
          placeholder={props.placeholder}
        />
        {props.isBulkUploadVisible === true && <BulkUpload />}
        {getValue(props, `downloadSelfComplianceReport`, false) && (
          <div
            className=" d-block justify-content-center cursor-pointer"
            onClick={props.handleDownloadSelfComplianceReport}
          >
            <img
              src="/images/download-gray.svg"
              style={{
                height: "24px",
                width: "24px",
                margin: "0px auto",
                marginLeft: "22px",
              }}
            />
            <p style={{ fontSize: "12px" }}>
              {getValue(props, `downloadLoading`, false)
                ? "Please wait..."
                : "DOWNLOAD"}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchHeader;
