// https://stackoverflow.com/questions/70585815/react-this-props-match-is-undefined
// https://reactrouter.com/docs/en/v6/getting-started/tutorial#reading-url-params
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NotFoundPage from "@pages/404";
import { PrivateRoutes } from "./private.routes";
import { PublicRoutes } from "./public.routes";
import { getValue } from "@utils/lodash";
const IndexRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {PublicRoutes.map((item, key) => {
          let Element: any = item.component;
          return (
            <Route
              key={key}
              path={item.path}
              element={
                !localStorage.getItem("accessToken") ? (
                  <Element />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          );
        })}
        {PrivateRoutes.map((item, key) => {
          let Element: any = item.component;
          return (
            <Route
              key={key}
              path={item.path}
              element={
                localStorage.getItem("accessToken") ? (
                  <Element name={getValue(item, `name`, "")} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          );
        })}
        <Route path={"*"} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default IndexRoutes;
