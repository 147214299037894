import React, { useRef, useState, useEffect } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
// import { NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router-dom";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import _ from "lodash";
import PromptModal from "@prompt/modal";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import {
  getSpecificOrganisation,
  updateOrganisation,
} from "@services/organisation.service";
import { mediaUpload } from "@services/upload.service";
import "./org-profile.scss";
import GearLoader from "@components/common/GearLoader";
import {
  getlocationManagementCities,
  getlocationManagementCountries,
  getlocationManagementStates,
} from "@services/list-management.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { StylesConfig } from "react-select";
import AdminNavigation from "@components/Admin/admin-navigation";
import { createOrganisation } from "@services/organisation.service";
import { useNavigate } from "react-router-dom";
interface IOrganizationDetailScreenProps {}

const OrganizationDetailScreen: React.FunctionComponent<IOrganizationDetailScreenProps> = (
  props: any
) => {
  let params = useParams();
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const intialState = {
    organisation_name: "",
    domain_name: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    phone_no: "",
    //user
    user_first_name: "",
    user_middle_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile_number: "",
  };
  const [request, setRequest] = useState<any>(intialState);
  const [dirtyFields, setDirtyFields] = useState<any>(intialState);
  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [citiesList, setCityList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getCountriesList();
    // let user = JSON.parse(getValue(localStorage, `user`, ""));
    if (getValue(params, `id`, "")) {
      getData();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (request.country && !request.state && !request.city) {
      getStatesList(request.country);
    }
    if (request.state) {
      getCitiesList(request.state);
    }
  }, [request.country, request.state]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    try {
      setLoading(true);
      let resp = await getSpecificOrganisation(params.id);
      if (resp) {
        setRequest({
          ...request,
          country: getValue(resp, `data.country`, ""),
          state: getValue(resp, `data.state`, ""),
          city: getValue(resp, `data.city`, ""),
          address1: getValue(resp, `data.address1`, ""),
          address2: getValue(resp, `data.address2`, ""),
          organisation_name: getValue(resp, `data.organisation_name`, ""),
          organisation_logo: getValue(resp, `data.organisation_logo`, ""),
          domain_name: getValue(resp, `data.domain_name`, ""),
          pincode: getValue(resp, `data.pincode`, ""),
          email: getValue(resp, `data.email`, ""),
          phone_no: getValue(resp, `data.phone_no`, ""),
          user_first_name: getValue(resp, `data.user_first_name`, ""),
          user_middle_name: getValue(resp, `data.user_middle_name`, ""),
          user_last_name: getValue(resp, `data.user_last_name`, ""),
          user_email: getValue(resp, `data.user_email`, ""),
          user_mobile_number: getValue(resp, `data.user_mobile_number`, ""),
        });
        setDirtyFields({
          ...dirtyFields,
          country: getValue(resp, `data.country`, ""),
          state: getValue(resp, `data.state`, ""),
          city: getValue(resp, `data.city`, ""),
          address1: getValue(resp, `data.address1`, ""),
          address2: getValue(resp, `data.address2`, ""),
          organisation_name: getValue(resp, `data.organisation_name`, ""),
          organisation_logo: getValue(resp, `data.organisation_logo`, ""),
          domain_name: getValue(resp, `data.domain_name`, ""),
          pincode: getValue(resp, `data.pincode`, ""),
          email: getValue(resp, `data.email`, ""),
          phone_no: getValue(resp, `data.phone_no`, ""),
          user_first_name: getValue(resp, `data.user_first_name`, ""),
          user_middle_name: getValue(resp, `data.user_middle_name`, ""),
          user_last_name: getValue(resp, `data.user_last_name`, ""),
          user_email: getValue(resp, `data.user_email`, ""),
          user_mobile_number: getValue(resp, `data.user_mobile_number`, ""),
        });
        if (getValue(resp, `data.country`, "")) {
          getStatesList(getValue(resp, `data.country`, ""));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCountriesList = async () => {
    try {
      let resp = await getlocationManagementCountries("");
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  const getStatesList = async (countryId: any) => {
    let payload = {
      // countryId: countryId,
      country_name: countryId,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (countryId)
      try {
        let resp = await getlocationManagementStates(queryRequest);
        if (resp) {
          setStates(
            getValue(resp, `data.states`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          );
        } else {
        }
      } catch (error) {}
  };
  const getCitiesList = async (stateId: any) => {
    let payload = {
      // stateId: stateId,
      state_name: stateId,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getlocationManagementCities(queryRequest);
      if (resp) {
        setCityList(
          getValue(resp, `data.cities`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    let user = JSON.parse(getValue(localStorage, `user`, ""));
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let payload = JSON.parse(JSON.stringify(request));
      payload.pincode = parseInt(payload.pincode);
      payload.country = payload.country;
      payload.state = payload.state;
      payload.city = payload.city;
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      if (getValue(params, `id`, "")) {
        try {
          setIsLoading(true);
          let resp = await updateOrganisation(
            getValue(params, `id`, ""),
            payload
          );
          if (resp) {
            setIsLoading(false);
            confirmNavigation();
            toast.success(getValue(resp, "message", ""));
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      } else {
        try {
          setIsLoading(true);
          let resp = await createOrganisation(payload);
          if (resp) {
            setIsLoading(false);
            confirmNavigation();
            toast.success(getValue(resp, "message", ""));
            navigate(`/admin/organisations/active/list`);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country") {
      // getStatesList(getValue(e, `id`, ""));
      setRequest({
        ...request,
        country: getValue(e, `value`, ""),
        state: null,
        city: null,
      });
    }
    if (type === "state") {
      // getCitiesList(getValue(e, `id`, ""));
      setRequest({
        ...request,
        state: getValue(e, `value`, ""),
        city: null,
      });
    }
    if (type === "city") {
      setRequest({
        ...request,
        city: getValue(e, `value`, ""),
      });
    }
    if (type === "time_zone") {
      setRequest({
        ...request,
        time_zone: e.value,
      });
    }
  };

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  //   const handleChangeDate = (e: any) => {
  //     setRequest({
  //       ...request,
  //       commencement_date: e,
  //     });
  //   };

  //   const handleChangeCountryCode = (e: any) => {};

  const handleChangeFile = async (e: any) => {
    let user = JSON.parse(getValue(localStorage, `user`, ""));
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      let resp = await mediaUpload(formData);
      if (resp) {
        let id = getValue(resp, `data.url`, "");
        let payload: any = {
          organisation_logo: id,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key] || payload[key] === undefined) {
            delete payload[key];
          }
        });
        let response = await updateOrganisation(
          getValue(user, `org_id`, ""),
          payload
        );
        if (response) {
          localStorage.setItem("organisation_logo", id);
          getData();
        }
      }
    } catch (error) {}
  };

  const handleChangeTextColor = (e: any, value: string) => {
    if (e.hex) {
      setRequest({
        ...request,
        [value]: e.hex,
      });
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ]: any = useCallbackPrompt(!isChanged);

  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 43,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };
  return (
    <DashboardLayout>
      <div className="">
        <PromptModal
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <AdminNavigation
          id={params.id}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          url={`/admin/organisations/active/list`}
          isChanged={isChanged}
          permissions={props.permissions}
        />
        <div className="organization_container">
          {loading ? (
            <GearLoader />
          ) : (
            <>
              <div className="user-details-wrapper m-0">
                <div className="checkpoint-active-details-wrapper__form-wrapper">
                  <div className="checkpoint-active-details-wrapper__form-content">
                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Organization Name <span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Organization Name"
                          className="form-control w-100"
                          name="organisation_name"
                          id="organisation_name"
                          value={getValue(request, `organisation_name`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Organisation Name"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Domain Name <span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Domain Name"
                          className="form-control w-100"
                          name="domain_name"
                          id="domain_name"
                          value={getValue(request, `domain_name`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Domain Name"
                          validator={simpleValidator}
                        />
                      </div>
                    </div>
                    <div className="user-details-wrapper__form-group form-group mb-3">
                      <label className="checkpoint-active-details__label">
                        Company Address (Line 1)<span>*</span>
                      </label>
                      <InputField
                        inputType="TEXTAREA"
                        placeholder="Enter Company Address (Line 1)"
                        className="form-control w-100"
                        name="address1"
                        id="address1"
                        label="Address Line1"
                        value={getValue(request, `address1`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="user-details-wrapper__form-group form-group mb-3">
                      <label className="checkpoint-active-details__label">
                        Company Address (Line 2)<span></span>
                      </label>
                      <InputField
                        inputType="TEXTAREA"
                        placeholder="Enter Company Address (Line 2)"
                        className="form-control w-100"
                        name="address2"
                        id="address2"
                        label="Address Line2"
                        value={getValue(request, `address2`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        // validator={simpleValidator}
                      />
                    </div>
                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                      <div className="user-details-wrapper__form-group form-group mb-3">
                        <label className="checkpoint-active-details__label">
                          Country <span>*</span>
                        </label>
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose Country"
                          className="form-control w-100"
                          name="country"
                          id="country"
                          options={countryList}
                          value={
                            getValue(countryList, `length`, 0) > 0
                              ? countryList.filter(
                                  (item: object) =>
                                    getValue(item, `name`, "") ==
                                    getValue(request, `country`, "")
                                )[0]
                              : {}
                          }
                          onChange={(e: any) =>
                            handleChangeSelect(e, "country")
                          }
                          label="Country"
                          validator={simpleValidator}
                          styles={customStyles}
                          isClear
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          State <span>*</span>
                        </label>
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose State"
                          className="form-control w-100"
                          name="state"
                          id="state"
                          label="Last Name"
                          options={states}
                          value={states.filter(
                            (item: object) =>
                              getValue(item, `name`, "") ==
                              getValue(request, `state`, "")
                          )}
                          onChange={(e: any) => handleChangeSelect(e, "state")}
                          validator={simpleValidator}
                          styles={customStyles}
                          isClear
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          City <span>*</span>
                        </label>
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose State"
                          className="form-control w-100"
                          name="city"
                          id="city"
                          label="City"
                          options={citiesList}
                          value={citiesList.filter(
                            (item: object) =>
                              getValue(item, `name`, "") ==
                              getValue(request, `city`, "")
                          )}
                          onChange={(e: any) => handleChangeSelect(e, "city")}
                          validator={simpleValidator}
                          styles={customStyles}
                          isClear
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Pincode <span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Pincode"
                          className="form-control w-100"
                          name="pincode"
                          id="pincode"
                          value={getValue(request, `pincode`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Pincode"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Phone Number<span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Phone Number"
                          className="form-control w-100"
                          name="phone_no"
                          id="phone_no"
                          label="Mobile Number"
                          value={getValue(request, `phone_no`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Email Address<span>*</span>
                        </label>
                        <InputField
                          inputType="EMAIL"
                          placeholder="Enter  Email Address"
                          className="form-control w-100"
                          name="email"
                          id="email"
                          value={getValue(request, `email`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Email"
                          validator={simpleValidator}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src="/images/navigation-icons/user-control.svg"
                        width={"25px"}
                      />
                      <p className="header_text ms-2">Client Admin</p>
                    </div>
                    <div className="border_bottom"></div>
                    <div className="checkpoint-active-details-wrapper__form-wrapper">
                      <div className="checkpoint-active-details-wrapper__form-content">
                        <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label">
                              First Name <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter First Name"
                              className="form-control w-100"
                              name="user_first_name"
                              id="user_first_name"
                              value={getValue(request, `user_first_name`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="First Name"
                              validator={simpleValidator}
                            />
                          </div>
                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label">
                              Middle Name <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Middle Name"
                              className="form-control w-100"
                              name="user_middle_name"
                              id="user_middle_name"
                              value={getValue(request, `user_middle_name`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Middle Name"
                              validator={simpleValidator}
                            />
                          </div>
                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label">
                              Last Name <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Last Name"
                              className="form-control w-100"
                              name="user_last_name"
                              id="user_last_name"
                              value={getValue(request, `user_last_name`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Last Name"
                              validator={simpleValidator}
                            />
                          </div>
                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label">
                              Email <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Email"
                              className="form-control w-100"
                              name="user_email"
                              id="user_email"
                              value={getValue(request, `user_email`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Email"
                              validator={simpleValidator}
                            />
                          </div>
                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label">
                              Mobile Number <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Mobile Number"
                              className="form-control w-100"
                              name="user_mobile_number"
                              id="user_mobile_number"
                              value={getValue(
                                request,
                                `user_mobile_number`,
                                ""
                              )}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Mobile Number"
                              validator={simpleValidator}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="user-details-wrapper__cta-btns"></div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <img src="/images/footer-image.svg" className="img-fluid" />
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default OrganizationDetailScreen;
