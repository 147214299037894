import { NavLink } from "react-router-dom";
import "./location-navigation.scss";
import "./inner-page-navigation.scss";
import { getValue } from "@utils/lodash";

export default function AdminNavigation(props: any) {
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <NavLink
              to={getValue(props, `url`, "") ? getValue(props, `url`, "") : `#`}
            >
              <img
                src="/images/back-link.svg"
                className="img-fluid"
                height={24}
                width={24}
              />
            </NavLink>
            <div className="inner-page-navigation-with-back-cta-button__header">
              <h4 className="inner-page-navigation-with-back-cta-button__header-title">
                {getValue(props, `title`, "")}
              </h4>
            </div>
          </div>
          {!getValue(props, `hideHeaderMenu`, false) && (
            <ul className="inner-page-navigation-with-back-cta-buttons__navigation d-inline-flex position-absolute">
              {!props.id && (
                <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                  <NavLink
                    to={`/admin/organisations/add`}
                    className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                  >
                    {getValue(props, `headerTitle`, "")
                      ? getValue(props, `headerTitle`, "")
                      : "Create Organization"}
                  </NavLink>
                </li>
              )}
              {props.id && props.id !== "undefined" && (
                <>
                  <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                    <NavLink
                      to={`/admin/organisations/active/detail/${props.id}`}
                      className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                    >
                      Organization
                    </NavLink>
                  </li>
                 
                </>
              )}
            </ul>
          )}
          {/* {getValue(props, `permissions`, []).includes("update") ? ( */}
          <div>
            <NavLink to={props.url}>
              <button className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3">
                Cancel
              </button>
            </NavLink>
            {props.isLoading ? (
              <button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
                Please wait...
              </button>
            ) : (
              <button
                className={`ascent-button ascent-button--header-buttons ascent-button--${
                  !props.isChanged ? "secondary" : "primary"
                }`}
                onClick={props.handleSubmit}
                disabled={props.isChanged}
              >
                {props.id ? "Update" : "Save"}
              </button>
            )}
          </div>
          {/* ) : (
            <button
              className={`ascent-button ascent-button--header-buttons ascent-button-secondary`}
            >
              .
            </button>
          )} */}
        </div>
      </section>
    </div>
  );
}
