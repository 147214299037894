import * as React from "react";
import { Modal } from "reactstrap";
import { getValue } from "@utils/lodash";
import Lottie from "lottie-react";
import bin from "@lottie/bin.json";
import './delete.scss'
interface IDeleteModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
  deleteValue: string;
}
const DeleteModal: React.FunctionComponent<IDeleteModalProps> = (props) => {
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      toggle={getValue(props, `isOpen`, "")}
    >
      <section className="add-popup-wrapper">
        <div className="add-popup-wrapper__popup">
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div style={{ width: "30%", height: "30%", alignSelf: "center" }}>
              <Lottie animationData={bin} loop={true} />
            </div>
          </div>
          <div className="add-popup-wrapper__form-group form-group">
            <p className="mt-3">
              Are you sure, You want to delete{" "}
              <b>{getValue(props, `deleteValue`, "")}</b> ?
            </p>
          </div>
          <div className="add-popup-wrapper__cta-wrapper">
            <button
              onClick={props.handleModal}
              className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            >
              No
            </button>
            <button
              className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
              onClick={props.handleSubmit}
            >
              Yes
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default DeleteModal;
