import "./index.scss";
import LoginPageLyout from "@layouts/login";
import { useState, useRef } from "react";
import { adminLogin } from "@services/auth.service";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import InputField from "@common/InputRuleFeild";
import { Form } from "reactstrap";
import { useNavigate } from "react-router-dom";

interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props: any) => {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let resp = await adminLogin({ email, password });
        if (resp) {
          localStorage.setItem("view", "client");
          setIsLoading(false);
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.accessToken`, "")
          );
          localStorage.setItem(
            "refreshToken",
            getValue(resp, `data.refreshToken`, "")
          );
          localStorage.setItem(
            "user",
            JSON.stringify(getValue(resp, `data.user`, ""))
          );
          window.location.href = `/admin/organisations/active/list`;
          // console.log(resp);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const handleNavigate = (e: any, url: string) => {
    e.stopPropagation();
    navigate(url);
  };
  return (
    <>
      <LoginPageLyout>
        <div className="m-auto form-wrapper__form">
          <Form
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   handleSubmit(e);
            // }}
          >
            <h2>Sign in to ExpenseBerry</h2>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <InputField
                inputType="EMAIL"
                id="email"
                name="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                placeholder="Enter Email"
                className="form-control"
                label="Email"
                validator={simpleValidator}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <InputField
                inputType="PASSWORD"
                id="password"
                name="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                placeholder="Enter Password"
                className="form-control"
                validator={simpleValidator}
                label={"Password"}
              />
            </div>
            <div className="form-cta-button">
              <button
                className="form-cta-button__secondary-btn"
                onClick={(e) => handleNavigate(e, "/reset-password")}
              >
                Forgot password?
              </button>
              {isLoading ? (
                <button className="form-cta-button__primary-btn">
                  Please wait...
                </button>
              ) : (
                <button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="form-cta-button__primary-btn"
                >
                  Next
                </button>
              )}
            </div>
          </Form>
          <br />
          {/* <div className="form-cta-button-forgot">
           
          </div> */}

          {/* <NavLink to="/login">Sign-in as Client </NavLink> */}
        </div>
      </LoginPageLyout>
    </>
  );
};

export default LoginPage;
