import { config } from "../env";
import { post, postFileUpload } from "./helpers/http-handler";

export const mediaUpload = (payload: object) =>
  postFileUpload(`${config.API_URL}/media-uploads/file/upload`, payload);

export const mediaUploadGenratePresignedURL = (payload: object) =>
  post(`${config.API_URL}/media-uploads/generate-presinged-url`, payload);

export const mediaUploadCreateS3DirectUpload = (payload: object) =>
  post(`${config.API_URL}/media-uploads/create-s3-direct-upload`, payload);
