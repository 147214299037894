export function authHeader(url) {
  let access_token = localStorage.getItem("accessToken");
  if (access_token) {
    return {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      "x-tenant-origin": "client.dev.tripex.co.in",
      // "x-tenant-origin":'tenant_1'
    };
  } else {
    return {
      "Content-Type": "application/json",
      "x-tenant-origin": "client.dev.tripex.co.in",
    };
  }
}
