import React from 'react';
import Header from '@components/common/Header/index';
import LeftNavigation from '@components/common/LeftNavigation/index';
import '../dashboard/dashboard.scss';

export default function DashboardLayout(props: any) {
  return (
    <>
      <Header />
      <section className="main-wrapper main-wrapper--no-navigation-menu">
        <main className="main-wrapper__content w-100">{props.children}</main>
      </section>
    </>
  );
}
