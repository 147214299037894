import { config } from "../env";
import {
  Delete,
  get,
  patch,
  post,
} from "./helpers/http-handler";

export const getAllOrganisation = (queryRequest: string) =>
  get(`${config.API_URL}/organisation?${queryRequest}`);

export const getSpecificOrganisation = (id: string) =>
  get(`${config.API_URL}/organisation/${id}`);

export const createOrganisation = (payload: object) =>
  post(`${config.API_URL}/organisation`, payload);

export const updateOrganisation = (id: string, payload: object) =>
  patch(`${config.API_URL}/organisation/${id}`, payload);

export const deleteOrganisation = (id: string) =>
  Delete(`${config.API_URL}/organisation/${id}`);
