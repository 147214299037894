import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { deleteOrganisation, getAllOrganisation } from "@services/organisation.service";
import { NavLink } from "react-router-dom";
import NavigationBar from "@components/NotificationBar";
import SearchHeader from "@components/common/SearchHeader";
import GearLoader from "@components/common/GearLoader";
import ScrollableDataTable from "@components/ScrollableDatatable/scrollableTable";
import Notfound from "@components/Not-Found";
import PaginationPage from "@components/pagination/pagination";
import DeleteModal from "@common/popup/DeleteModal";
import { toast } from "react-toastify";

function OrganizationInActiveList(props: any) {
  let navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0) {
      getData();
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {};

    payload = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      status: "inactive",
      sort_by: params.sort_by,
    };

    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllOrganisation(queryRequest);
      if (resp) {
        console.log(resp, "======>");
        setList(
          getValue(resp, `data.organisations`, []).map((item: object) => ({
            ...item,
            created_at: getValue(item, `created_at`, ""),
          }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);

    let payload: any = {
      search_text: e.target.value,
    };
    // removing null values
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.page_no = page_no;

    let queryRequest = QueryRequestHelper(params);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.limit = limit;
    let queryRequest = QueryRequestHelper(params);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Table section                              */
  /* -------------------------------------------------------------------------- */
  const columns = React.useMemo(
    () => [
      {
        Header: "Organization ID",
        accessor: "organisation_id",
        width: 250,
        Cell: ({ row }: any) => {
          return (
            <NavLink to={`/checkpoint/active-detail/${row.original.id}`}>
              <>{row.values.organisation_id}</>
            </NavLink>
          );
        },
      },
      {
        Header: "Organization Name",
        accessor: "organisation_name",
        width: 250,
      },
      {
        Header: "Domain",
        accessor: "domain_name",
        width: 250,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 250,
      },
      {
        Header: "Country",
        accessor: "country",
        width: 250,
      },
      {
        Header: "State",
        accessor: "state",
        width: 250,
      },
      {
        Header: "City",
        accessor: "city",
        width: 250,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        width: 250,
      },

      // {
      // 	Header: "Action",
      // 	width: 250,
      // 	Cell: ({ row }: any) => {
      // 		return (
      // 			<>
      // 				<Button
      // 					color="primary"
      // 					onClick={() => handleEdit(row.original.id)}
      // 				>
      // 					Edit
      // 				</Button>
      // 				&nbsp;&nbsp;&nbsp;
      // 				<Button
      // 					color="danger"
      // 					onClick={() =>
      // 						handleDeleteFun(row.original.id, row.values.checkpoint_id)
      // 					}
      // 				>
      // 					Delete
      // 				</Button>
      // 			</>
      // 		);
      // 	},
      // },
    ],
    []
  );
  const rowClick = (obj: object) => {
    navigate(
      `/admin/organisations/active/detail/${getValue(obj, `original.id`, "")}`
    );
  };
  const getTrProps = (rowInfo: any) => {
    return {};
  };
  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      list.length > 0
        ? list.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];
    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };

  const handleChangeActiveStatus = async () => {
    // try {
    //   let resp = await adminUpdateBulkStatusCheckpoint({
    //     ids: selectedIDs,
    //     is_active: false,
    //   });
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     setSelectedIDs([]);
    //     getData();
    //   }
    // } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteOrganisation(selectedIDs[0]);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };
  return (
    <DashboardLayout>
      {getValue(selectedIDs, `length`, 0) > 0 ? (
        <NavigationBar
          selectedIDs={selectedIDs}
          isMarkInactiveSectionVisible={true}
          handleChangeActiveStatus={handleChangeActiveStatus}
          text={"MARK INACTIVE"}
          handleRemoveNavItems={handleRemoveNavItems}
          permissions={props.permissions}
          handleDeleteFun={handleDeleteFun}
          delete={getValue(selectedIDs,`length`,0) === 1?true:false}
        />
      ) : null}
      <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
        <h4 className="dashbaord-wrapper__header-title">Active</h4>
        <div></div>
      </div>
      <div className="admin-wrapper position-relative">
        <div className="admin-wrapper position-relative">
          <SearchHeader
            route={"/admin/organisations/add"}
            searchInput={search_text}
            showSearchTextbox={showSearchTextbox}
            toggleSearch={toggleSearch}
            toggleSearchClose={toggleSearchClose}
            handleChangeSearch={handleChangeSearch}
            // isBulkUploadVisible={
            //   getValue(props, `permissions`, []).includes("update")
            //     ? true
            //     : false
            // }
            // smartFilter={true}
            // setFilterFormVisibility={() => {
            //   setFilterVisibility(!isFilterVisible);
            // }}
            // isFilterVisible={isFilterVisible}
            placeholder={"Search Checkpoint ID"}
            add={"true"}
            migrate
          />
        </div>
      </div>
      {isLoading ? (
        <GearLoader />
      ) : list.length > 0 ? (
        <div
          className={
            getValue(selectedIDs, `length`, 0) > 0
              ? "scrollable_table_container_nav"
              : "scrollable_table_container"
          }
        >
          <ScrollableDataTable
            columns={columns}
            data={list}
            getTableState={getTableState}
            rowClick={rowClick}
            check={check}
            selectedIDs={selectedIDs}
            getTrProps={getTrProps}
          />
        </div>
      ) : (
        <>
          {Object.keys(urlParams).length !== 0 ? (
            <>
              <Notfound />
            </>
          ) : (
            <Notfound />
          )}
        </>
      )}
      <div className="footer-wrapper d-flex justify-content-between">
        {list.length > 0 && (
          <img src="/images/building-illustration.svg" className="img-fluid" />
        )}
        {totalCount > 100 ? (
          <PaginationPage
            totalCount={totalCount}
            limit={limit}
            pageNumber={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
          />
        ) : (
          ""
        )}
      </div>
      <DeleteModal
        isOpen={isDelete}
        handleModal={handleDelete}
        handleSubmit={handleDeleteFunction}
        deleteValue={deleteValue}
      />
    </DashboardLayout>
  );
}

export default OrganizationInActiveList;
