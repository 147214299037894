import React, { useEffect } from "react";
import styled from "styled-components";
import {
	useTable,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useResizeColumns,
	useBlockLayout,
} from "react-table";
// A great library for fuzzy filtering/sorting items
// import matchSorter from "match-sorter";

//import makeData from './MakeData';
import { getValue, setValue } from "@utils/lodash";

//var reactListbox = require("react-listbox");

const Styles = styled.div`
	.table > :not(:first-child) {
		border-top: none;
	}
	input[type="checkbox"] {
		width: 15px;
		height: 15px;
	}
	// .table > :last-child {
	// 	border-bottom: 1px solid #dadce0;
	// }
	.table {
		max-width: 100%;
		overflow-x: auto;
		// width: 1200px;
		// display: inline-block;
		border-spacing: 0;
		border-right: 1px solid #dadce0;
		margin: 0 0 8px;
		.th {
			color: #5f6368;
			font-size: 14px !important;
			font-weight: 500;
		}
		.th,
		.td {

			// -----> to align center start
			display: flex !important;
			box-sizing: border-box;
			width: 350px;
			justify-content: center;
			flex-direction: column !important;
			// --->end


			margin: 0;
			padding: 16px 9px;
			border-bottom: 1px solid #dadce0;
			border-right: 1px solid #dadce0;
			// color: #3c4043;
			font-size: 12px;
			word-break: break-word;
			${"" /* In this example we use an absolutely position resizer,
       			so this is required. */}
			position: relative;
			:last-child {
				border-right: 0;
			}
			:first-child {
				// background:red;
				// width:60px !important;
			}

			.resizer {
				display: inline-block;
				// background: blue;
				width: 1px;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				transform: translateX(50%);
				z-index: 1;
				${"" /* prevents from scrolling while dragging on touch devices */}
				touch-action:none;

				&.isResizing {
					background: red;
				}
			}
		}
	}
`;

// Define a default UI for filtering
function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;

	return (
		<input
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

// function fuzzyTextFilterFn(rows, id, filterValue) {
//   return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
// }

// Let the table remove the filter if the string is empty
// fuzzyTextFilterFn.autoRemove = val => !val;

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<label>
					<input
						type="checkbox"
						className="default-checkbox"
						ref={resolvedRef}
						{...rest}
						onClick={(e) => e.stopPropagation()}
					/>
					<div
						className="custom-checkbox m-auto mt-3"
						onClick={(e) => e.stopPropagation()}
					>
						<img
							src="/images/uploaded-details/tick.svg"
							className="upload-tick img-fluid"
						/>
					</div>
				</label>
			</>
		);
	}
);

// Our table component
function Table({
	columns,
	data,
	getTableState,
	rowClick,
	selectedIDs,
	check,
	checkboxlogo,
	getTrProps,
}) {
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			// fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
							.toLowerCase()
							.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			width: 60,
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		state,
	} = useTable(
		{
			columns,
			data,
			// defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: { pageSize: 100 },
			getTableState,
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
		useBlockLayout,
		useResizeColumns,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: "selection",
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							{!checkboxlogo ? (
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							) : (
								<div style={{ display: "flex", justifyContent: "center" }}>
									<img src="/images/taskback.svg" lassName="img-fluid ml-5" />
								</div>
							)}
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							{!checkboxlogo ? (
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							) : (
								<div style={{ display: "flex", justifyContent: "center" }}>
									<img src="/images/taskback.svg" className="img-fluid ml-5" />
								</div>
							)}
						</div>
					),
					width: 50,
				},
				...columns,
			]);
		}
	);

	useEffect(() => {
		getTableState(state);
	}, [getValue(state, `selectedRowIds`, [])]);

	useEffect(() => {
		if (selectedIDs.length === 0) {
			state.selectedRowIds = {};
			getTableState(state);
		}
	}, [selectedIDs && check]);
	return (
		<>
			{/* <Pagination {...paginationProps} /> */}
			<div className="datatble-wrapper">
				<div {...getTableProps()} className="table">
					<div>
						{/* @ts-ignore */}
						{headerGroups.map((headerGroup, index) => {
							return (
								<div
									{...headerGroup.getHeaderGroupProps()}
									className="tr table-header"
								>
									{headerGroup.headers.map((column, i) => (
										<div
											{...column.getHeaderProps({ style: {} })}
											className="th"
										// className={i === 0 ? "th w-auto" : "th"}
										>
											{column.render("Header")}
											{/* Add a sort direction indicator */}
											{/* Use column.getResizerProps to hook up the events correctly */}
											{/* @ts-ignore */}
											{/* Render the columns filter UI */}
											<div
												//@ts-ignore
												{...column.getResizerProps({ canResize: true })}
												className={`resizer ${
													// @ts-ignore
													column.isResizing ? "isResizing" : ""
													}`}
											/>
										</div>
									))}
								</div>
							);
						})}
					</div>
					<div {...getTableBodyProps()}>
						{/* @ts-ignore */}
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<div
									className="tr table-hover-row-color"
									onClick={() => rowClick(row)}
									{...row.getRowProps()}
								>
									{/* @ts-ignore */}
									{row.cells.map((cell, key) => {
										let styles = {
											...cell.getCellProps(
												key === 1 && {
													...getTrProps(getValue(row, `original`, {})),
												}
											),
										};
										return (
											<div {...cell.getCellProps()} className="td">
												<p
													{...cell.getCellProps(
														key === 1 && {
															...getTrProps(getValue(row, `original`, {})),
														}
													)}
												></p>
												<p
													style={{
														marginLeft:
															key === 1 &&
															getValue(styles, `style.background`, "") &&
															"25px",
													}}
												>
													{key === 1 && getValue(row, `original.documents.length`, 0) > 0 && 
													<span style={{ marginLeft: '4px', marginRight: '4px' }}>
														<img 
														src="/images/attach.svg" 
														className="img-fluid" 
														style={{ height: '20px', width: '20px' }} 
														/>
													</span>}
													{cell.render("Cell")}
												</p>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{/* <Pagination {...paginationProps} /> */}
			{/* <br /> */}
			{/* <div className="row">
				<div className="col-xs-6">
					<pre>
						<code>
							Pagination:{" "}
							{JSON.stringify({ pageIndex, pageSize, pageCount }, null, 2)}
						</code>
					</pre>
				</div>
			</div> */}
		</>
	);
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function ScrollableReactTable(props) {
	const columns = React.useMemo(
		() => [
			{
				Header: "User ID",
				accessor: "userid",
				style: { "white-space": "unset", overflow: "hidden" },
				width: 250,
			},
			{
				Header: "First Name",
				accessor: "firstname",
				style: { "white-space": "unset" },
				width: 250,
			},
			{
				Header: "Last Name",
				accessor: "lastname",
				style: { "white-space": "unset" },
				width: 250,
			},
			{
				Header: "Access Level",
				accessor: "accesslevel",
				style: { "white-space": "unset" },
				width: 250,
			},
			{
				Header: "Added on",
				accessor: "addedon",
				style: { "white-space": "unset" },
				width: 250,
				//width: 60,
			},
			{
				Header: "Last Signed-in",
				accessor: "lastsignedin",
				style: { "white-space": "unset" },
				width: 250,
			},
		],
		[]
	);
	const data = React.useMemo(
		() => [
			{
				id: 1,
				userid:
					"Prabhu@Accenture.com Prabhu@Accenture.com Prabhu@Accenture.com Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 2,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 3,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 4,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 5,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 6,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 7,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 8,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 9,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 10,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 11,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 12,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
			{
				id: 13,
				userid: "Prabhu@Accenture.com",
				firstname: "Prabhakar",
				lastname: "Murthy",
				accesslevel: "SuperAdmin",
				addedon: "23-May-2021",
				lastsignedin: "23-May-2021",
			},
		],
		[]
	);
	return (
		<>
			<Styles>
				<Table
					columns={
						getValue(props, `columns.length`, 0) > 0
							? getValue(props, `columns`, [])
							: columns
					}
					data={
						getValue(props, `data.length`, 0) > 0
							? getValue(props, `data`, [])
							: data
					}
					getTableState={props.getTableState}
					rowClick={props.rowClick}
					selectedIDs={props.selectedIDs}
					check={props.check}
					checkboxlogo={props.checkboxlogo}
					getTrProps={props.getTrProps}
				// getTableState={state}
				/>
			</Styles>
		</>
	);
}

export default ScrollableReactTable;
