import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import "./LeftNavigation.scss";
import { useLocation } from "react-router-dom";

export default function LeftNavigation() {
  const location = useLocation();
  const [clientNavigationMenu] = useState([
    {
      title: "Organization",
      icon: "/images/navigation-icons/org.svg",
      link: "/admin/organisations/active/list",
      parentLink: "/admin/organisations/active/list",
      name: "admin",
      // subMenu: [
      //   {
      //     title: "Active",
      //     link: "/admin/organisations/active/list",
      //     name: "OrganizationActive",
      //   },
      //   {
      //     title: "Inactive",
      //     link: "/admin/organisations/inactive/list",
      //     name: "OrganizationInactive",
      //   },
      // ],
    },
    {
      title: "Users",
      icon: "/images/navigation-icons/user-control.svg",
      link: "/admin/users",
      parentLink: "/admin/users",
      name: "admin",
    },
  ]);

  useEffect(() => {
    // console.log(location);
  }, []);

  return (
    <nav className="navigation-menu">
      <ul>
        {clientNavigationMenu.map((menuObj, index) => {
          return (
            <>
              <li
                className={`navigation-menu__menu-item ${
                  !getValue(menuObj, `subMenu`, "")
                    ? "navigation-menu__no-sub-menu-item"
                    : ""
                }
								${
                  !getValue(menuObj, `subMenu`, "") &&
                  window.location.pathname ===
                    getValue(menuObj, `parentLink`, "")
                    ? "active"
                    : ""
                }
			`}
              >
                <label
                  className={`d-flex w-100 align-items-flex-start ${
                    getValue(menuObj, `subMenu`, "") ? "cursor-pointer" : ""
                  }`}
                  htmlFor={`nav-menu-${index}`}
                >
                  <div className="navigation-menu__menu-icon">
                    <img
                      src={menuObj.icon}
                      className="material-symbols-outlined img-fluid"
                    />
                  </div>
                  <div className="d-flex align-items-flex-start justify-content-between navigation-menu__menu-elements">
                    {getValue(menuObj, `subMenu`, "") ? (
                      <a className="nav-link">{menuObj.title}</a>
                    ) : (
                      <NavLink
                        to={getValue(menuObj, `link`, "")}
                        className="nav-link w-100"
                      >
                        <a>{menuObj.title}</a>
                      </NavLink>
                    )}

                    {getValue(menuObj, `subMenu`, "") && (
                      <img
                        src="/images/arrow-down.svg"
                        className="img-fluid dropdown-arrow cursor-pointer"
                      />
                    )}
                  </div>
                </label>
                {getValue(menuObj, `subMenu`, "") && (
                  <input
                    type="checkbox"
                    id={`nav-menu-${index}`}
                    name="navigation-menu"
                    className="left-navigation__checkbox d-none"
                    defaultChecked={location.pathname.includes(
                      menuObj.parentLink
                    )}
                  />
                )}
                {getValue(menuObj, `subMenu`, "") && (
                  <ul className={`navigation-menu__sub-menu`}>
                    {getValue(menuObj, `subMenu`, "")?.map(
                      (subMenuObj: any, index: number) => {
                        return (
                          <NavLink
                            to={getValue(subMenuObj, `link`, "")}
                            key={`navigation-menu__sub-menu-${index}`}
                          >
                            <li
                              className={`${index} navigation-menu__sub-menu-link
                            								${
                                              window.location.pathname ===
                                                getValue(
                                                  subMenuObj,
                                                  `link`,
                                                  ""
                                                ) && "active"
                                            }`}
                            >
                              <span>{subMenuObj.title}</span>
                            </li>
                          </NavLink>
                        );
                      }
                    )}
                  </ul>
                )}
              </li>
            </>
          );
        })}
      </ul>
    </nav>
  );
}
